<template>
  <div id="app">
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

import drawMixin from '@/utlis/drawMixin.js'

export default {
  name: 'App',
  mixins: [drawMixin],
  components: {
    HelloWorld
  },
  methods:{
    
  }
}
</script>

<style>

body{
  margin: 0;
  padding: 0;
}
.imageApp{
  height: 100% ;
  width: 100%;
}
</style>
