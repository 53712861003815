<template>
  <div class="hello">
    <img class="imageApp" src="../assets/background.png" />

    <el-row>
      <el-col :span="12"
        ><span class="inner">Boatman Media LIMITED</span></el-col
      >
    </el-row>
    <el-row class="contain">
      <el-col :span="4"
        ><div>
          <img class="images" id="images1" src="../assets/image1.jpg" /></div
      ></el-col>
      <el-col :span="4"
        ><div>
          <img class="images" id="images1" src="../assets/image2.jpg" /></div
      ></el-col>
      <el-col :span="4"
        ><div>
          <img class="images" id="images1" src="../assets/image3.jpg" /></div
      ></el-col>
      <el-col :span="4"
        ><div>
          <img class="images" id="images1" src="../assets/image4.jpg" /></div
      ></el-col>
      <el-col :span="4"
        ><div>
          <img class="images" id="images1" src="../assets/image5.png" /></div
      ></el-col>
      <el-col :span="4"
        ><div>
          <img class="images" id="images1" src="../assets/image6.png" /></div
      ></el-col>
    </el-row>
    <div class="borders"></div>
    <div class="bottom2">
      contact us: account@screeni2fga.co
      <div style="margin-top:10px">© 2023 Copyright: Boatman Media LIMITED account@screeni2fga.co</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<style>
.borders {
  border: 1px solid rgb(242, 242, 242);
}
.contain {
  margin-top: -5px;
  padding: 10px;
  /* background-color: rgb(242, 242, 242); */
}
.contain .images {
  width: 50px;
  height: 50px;
}
.bottom1 {
  position: absolute;
  bottom: 5vw;
  left: 5vw;
}
.bottom2 {
  position: fixed;
  bottom: 2vw;
  left: 5vw;
}
.bottom3 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.inner {
  z-index: 1;
  font-weight: bold;
  font-size: 3vw;
  color: aliceblue;
  position: absolute;
  left: 3vw;
  bottom: 5vw;
}
</style>
